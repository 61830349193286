import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import File from '@projectTypes/File';
import { renderHtmlSelector } from '@selectors';
import { debounce } from 'debounce';
import { Box } from '@chakra-ui/react';
import { setTargetFile } from '@slices/projectSlices';

interface WebDevEditorProps {
  displayedFile: File;
}

const changeHtml = debounce((newHtml, setHtml) => {
  setHtml(newHtml);
}, 1000);

const WebDevEditor = (props: WebDevEditorProps) => {
  const { displayedFile } = props;
  const dispatch = useDispatch();
  const { html: renderHtml, id: renderHtmlId } = useSelector(renderHtmlSelector);
  const [htmlCode, setHtml] = useState(renderHtml);
  const debounceOnReload = useRef(renderHtmlId);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [iframeWidth, setIframeWidth] = useState('100%');
  const startX = useRef(0);
  const startWidth = useRef(0);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (debounceOnReload.current === renderHtmlId) {
      changeHtml(renderHtml, setHtml);
    } else {
      setHtml(renderHtml);
    }
    debounceOnReload.current = renderHtmlId;

  }, [renderHtml]);

  useEffect(() => {
    if (displayedFile) {
      setNewRender(displayedFile.name);
    }
  }, [displayedFile]);

  function getNameFromPath(path: string) {
    let fileNameList = path.split('/');
    let fileName = fileNameList[fileNameList.length - 1];

    return fileName;
  }

  function setNewRender(fileName: string) {
    if (fileName.split('.').includes('html')) {
      dispatch(setTargetFile(fileName));
    } else {
      dispatch(setTargetFile(displayedFile.name));
    }
  }
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging || !containerRef.current) return;

      const parentWidth = containerRef.current.parentElement?.offsetWidth || 0;
      const maxWidth = parentWidth * 0.8;
      const offset = e.clientX - startX.current;
      const newWidth = startWidth.current - offset;
      
      const clampedWidth = Math.min(
        Math.max(newWidth, 320), 
        maxWidth                
      );

      setIframeWidth(`${clampedWidth}px`);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.classList.remove('select-none');
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.body.classList.add('select-none');
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);


  const handleResizeStart = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    startX.current = e.clientX;
    startWidth.current = iframeRef.current?.offsetWidth || 0;
  };

  return !htmlCode ? (
    <Box
      pl={'8px'}
      pt={'8px'}
      textColor={'lightgray'}
      wordBreak="unset"
      textAlign="center"
    >
      <b>
        Please create an HTML file and write some code to enable the live preview!
      </b>
    </Box>
  ) : (<div 
    ref={containerRef}
    style={{ 
      position: 'relative', 
      width: iframeWidth,
      marginLeft: 'auto',
      height: '100%'
    }}
  >
    <div
      style={{
        position: 'absolute',
        left: '-4px',
        top: 0,
        bottom: 0,
        width: '8px',
        cursor: 'col-resize',
        zIndex: 10,
        backgroundColor: isDragging ? '#3182ce' : 'transparent',
        transition: 'background-color 0.2s ease'
      }}
      onMouseDown={handleResizeStart}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* Half-circle indicator */}
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          clipPath: 'inset(0 16px 0 0)',
          backgroundColor: isDragging || isHovering ? '#3182ce' : '#cbd5e0',
          transition: 'background-color 0.2s ease',
        }}
      />
    </div>
    <iframe
      key={displayedFile.id + displayedFile.fileCounter}
      data-cy="renderIframe"
      title="renderIframe"
      srcDoc={htmlCode}
      id="renderIframe"
      ref={iframeRef}
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'white',
        border: 'none',
        transition: isDragging ? 'none' : 'width 0.2s ease',
        pointerEvents: isDragging ? 'none' : 'auto'
      }}
      onLoad={(e) => {
        if (e?.target) {
          const newRenderFileName = getNameFromPath(
            (e.target as any).contentWindow.location.href
          );
          setNewRender(newRenderFileName);
        }
      }}
    />
  </div>
  );
};

export default WebDevEditor;