import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

import { ProjectType } from '@/types/Project';
import File from '@projectTypes/File';
import { editorSelector, selectedProjectSelector } from '@selectors';
import useResizeObserver from '@hooks/useResizeObserver';
import { Box, Button, calc, Flex, position } from '@chakra-ui/react';
import useShortcuts from '@hooks/useShortcuts';
const MonacoEditor = dynamic(import(`@monaco-editor/react`) as any, {
  ssr: false,
}) as any;

import useEditorFeatures from './hooks/useEditorFeatures';
import WebDevEditor from '../WebDevEditor/WebDevEditor';
import { render } from 'react-dom';

const LINE_HEIGHT = 18;

interface CodeEditorProps {
  displayedFile: File;
  language: string;
  width: number;
  onChange: (value: any) => void;
  fontSize: number;
}

const CodeEditor = (props: CodeEditorProps) => {
  const { displayedFile, language, width, onChange, fontSize } = props;
  const selectedProject = useSelector(selectedProjectSelector);

  useResizeObserver('.monaco-mouse-cursor-text');
  // eslint-disable-next-line no-unused-vars
  //  const isWebProject = selectedProject.type === ProjectType.WEB;
  const isWebProject = displayedFile.contentType === 'html';


  const [_minHeight, setMinHeight] = useState(500);

  const [editorDidMount] = useEditorFeatures(displayedFile);

  const [codeEditorWidth, setCodeEditorWidth] = useState();
  useShortcuts(displayedFile?.id, selectedProject);

  useEffect(() => {
    setMinHeight(
      displayedFile.newContent.split(/\r\n|\r|\n/).length * LINE_HEIGHT * 2,
    );
  }, [displayedFile.fileCounter, displayedFile.id]);
 

  return (
    <Flex 
      h="100vh" 
      w="100%" 
      position="relative"
      bg="red.100" // Visual debug
      flexShrink={0}
    >
      <Box 
        flex="1" 
        minW="300px" 
        h="100vh"
        bg="white" 
        borderRight="2px solid gray"
      >
        <MonacoEditor
            key={width}
            width="100%"
            height="100vh"
            language={language}
            theme="vs-dark"
            value={displayedFile.newContent}
            onChange={onChange}
            onMount={editorDidMount}
            options={{
              scrollbar: { vertical: 'hidden' },
              wordWrap: 'on',
              lineHeight: fontSize * 1.5,
              fontSize,
              minimap: { enabled: false },
              padding: { top: 8 },
            }} 
          />
      </Box>

      {isWebProject && (
            <WebDevEditor {...props}/>
      )}
    </Flex>
  );
}

export default CodeEditor;
