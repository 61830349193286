import React, { useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import LoginForm from './LoginForm';
import { useTranslation } from 'next-i18next';

const UserAccountModal = (props: { isGuest?: boolean }) => {
  const { isGuest } = props;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      // Fix Safari scroll behavior
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    };
  }, []);

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal isOpen onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader> {t('label.login')} </ModalHeader>
        <ModalBody>
          <LoginForm isGuest={isGuest} />
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default UserAccountModal;
