import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  ChatIcon,
  CheckIcon,
  CloseIcon,
  InfoIcon,
  RepeatClockIcon,
  RepeatIcon,
  PlusSquareIcon,
  DeleteIcon,
} from '@chakra-ui/icons';
import {
  FaPlay,
  FaSave,
  FaTrashRestore,
  FaShare,
  FaPlug,
  FaPlus,
  FaMinus,
  FaYoutube,
  FaTerminal,
  FaFastForward,
} from 'react-icons/fa';
import { BiReset, BiTestTube } from 'react-icons/bi';
import { FiMonitor } from 'react-icons/fi';
import { ImArrowRight, ImLink } from 'react-icons/im';
import { RiGitRepositoryCommitsFill } from 'react-icons/ri';
import { IoMdGitCommit } from 'react-icons/io';
import { MdReplay } from 'react-icons/md';

import useDispatch from '@hooks/useDispatch';
import {
  increaseFileCounter,
  resetFile,
  saveProjectFiles,
  setJupyterState,
  setShowFileDiff,
} from '@slices/fileSlices';
import {
  compileProject,
  runChat,
  runFile,
  runLogs,
  setLastCompileId,
  setPcbType,
  setupEspDevice,
  testFile,
  trainProject,
} from '@slices/terminalSlices';
import {
  resetProject,
  setShowProjectDiff,
  setShowProjectShare,
  showFileDiff,
  showProjectDiff,
  setShowProjectInfo,
} from '@slices/projectSlices';
import {
  selectedProjectSelector,
  projectFilesSelector,
  displayedFileSelector,
  projectsSelector,
  filesSelector,
  isProjectPython,
  isProjectPlatformio,
  pythonIsLoading,
  isPythonTestable,
  currentIsLive,
  publicUsernameSelector,
  pythonIsTesting,
  deviceSelector,
  userSelector,
  commitSelector,
  isProjectEmbededPython,
  rightSidebarSelector,
  isCurrentFileActionsPy,
  anyFileHasError,
  deviceHasNewVersionSelector,
  commitIdToRunAgainSelector,
  espDeviceSelector,
  pcbTypeSelector,
} from '@selectors';
import { PcbType, UploadType } from '@projectTypes/Terminal';
import { ProjectType } from '@projectTypes/Project';
import { useConfirmDelete, useConfirm, usePrompt } from 'chakra-confirm';
import { isMac } from '@helpers/getOs';
import {
  decreaseFontSize,
  increaseFontSize,
  resetFontSize,
  setIsRightSidebarOpen,
} from '@slices/globalSlices';
import { updateUser } from '@slices/userSlices';
import {
  getDevices,
  getOnlineDevicesInfo,
  pushCommitToDevice,
  setShowDeviceModal,
} from '@slices/deviceSlices';
import {
  getCommits,
  setDiffWithWorkingDraft,
  setShowCommitDiff,
  setShowCommitModal,
} from '@slices/commitSlices';
import AuthService from '@services/AuthService';
import jupyterService from '@services/JupyterService';
import MqttService from '@services/MqttService';
import config from '@config';
import useKeyPress from '@hooks/useKeyPress';
import getQueryStringValue from '@helpers/getQueryStringValue';
import isSerialAvailable from '@helpers/isSerialAvailable';
import LocaleSelect from '../../components/LocaleSelect';
import { i18n, useTranslation } from 'next-i18next';

interface ToolbarContainerProps {
  isSmall?: boolean;
}

interface Option {
  label: string;
  icon: ReactElement;
  action: () => void;
  isDisabled?: boolean;
  isDisabledLabel?: string;
  hide?: boolean;
  highlight?: boolean;
  alert?: boolean;
  colorScheme?: string;
}

interface Space {
  icon: 'space';
  label: string;
  hide?: boolean;
}

function onLanguageChange(lng: any) {
  i18n.changeLanguage(lng);
}

const ToolbarContainer = ({ isSmall }: ToolbarContainerProps) => {
  const dispatch = useDispatch();
  const projectState = useSelector(projectsSelector);
  const fileState = useSelector(filesSelector);
  const projectFiles = useSelector(projectFilesSelector);
  const selectedProject = useSelector(selectedProjectSelector);
  const displayedFile = useSelector(displayedFileSelector);
  const pythonLoading = useSelector(pythonIsLoading);
  const pythonTesting = useSelector(pythonIsTesting);
  const isRightSidebarOpen = useSelector(rightSidebarSelector);
  const commitIdToRunAgain = useSelector(commitIdToRunAgainSelector);
  const user = useSelector(userSelector);
  const isEmbedded = useSelector(isProjectEmbededPython);
  const isActionsFile = useSelector(isCurrentFileActionsPy);
  const toast = useToast();
  const hasError = useSelector(anyFileHasError);
  const commit = useSelector(commitSelector);
  const { t } = useTranslation('common');

  const { connectedDevice, devices, lastRanProjectId } = useSelector(
    deviceSelector,
  );
  const hasNewVerionsNotify = useSelector(deviceHasNewVersionSelector);
  const espDevice = useSelector(espDeviceSelector);
  const pcbType = useSelector(pcbTypeSelector);

  const projectHasChanges = projectFiles.reduce(
    (acc, file) => acc || file?.isChanged,
    false,
  );

  const isChatOptionAvailable = selectedProject && selectedProject.runId;
  const isGroupProject = selectedProject && selectedProject.isGroupProject;
  const isJupyterContentType = displayedFile?.name.includes(
    config.jupyterContentType,
  );

  const currentTemplateIsLive = useSelector(currentIsLive);
  const publicUsername = useSelector(publicUsernameSelector);
  const currentUsernameText = publicUsername
    ? `${t('username.prompt.setPublic')} @${publicUsername}`
    : t('username.prompt.setPublic');

  const testInQuery = getQueryStringValue('test') === 'true';
  const isProjectWeb = selectedProject?.type === ProjectType.WEB;

  const prompt = usePrompt<string>({
    title: 'Change publicUsername',
    defaultState: publicUsername,
    body: (
      <Stack>
        <Heading size="sm" fontWeight="normal">
          {publicUsername && (
            <>
              {t('prompt.changePublicUsername.title')}{' '}
              <Box as="span" fontWeight="bold">
                {publicUsername}
              </Box>
            </>
          )}
        </Heading>
        <Heading size="sm" color="red.600">
          Don&apos;t put any identifying data here like <br />
          (email, real first and last name)
        </Heading>
      </Stack>
    ),
  });

  const confirmExit = useConfirmDelete({
    title: t('confirm.exit.title'),
    body: t('confirm.exit.body'),
    buttonText: t('confirm.exit.buttonText'),
  });

  const confirmAlert = useConfirm({
    title: t('confirm.syntaxError.title'),
    body: t('confirm.syntaxError.body'),
  });

  const isPlatformio = useSelector(isProjectPlatformio);
  const isPython = useSelector(isProjectPython);
  const isPythonNotTestable = !useSelector(isPythonTestable);
  const isPythonRunDisabled =
    displayedFile?.contentType !== 'py' || pythonLoading;

  const fileHasError = projectFiles.reduce(
    (acc, file) => acc || file?.hasError,
    false,
  );

  useEffect(() => {
    if (isEmbedded && user.additionalUserInfo && devices.length === 0) {
      dispatch(getDevices(user.additionalUserInfo.schoolIds));
    }
  }, [isEmbedded, user.additionalUserInfo]);

  useEffect(() => {
    if (hasNewVerionsNotify) {
      toast({
        title: t('New update.'),
        description: t('Connected device has an available update.'),
        status: t('success'),
        duration: 3000,
        isClosable: true,
      });
    }
  }, [hasNewVerionsNotify]);

  const commitToDevice = () => {
    //    if (isEmbedded) {
    if (connectedDevice) {
      dispatch(
        getCommits({
          projectId: selectedProject.id,
          userId: user.id,
          deviceId: connectedDevice.id,
        }),
      );
      dispatch(setShowCommitModal(true));
    } else {
      dispatch(
        getCommits({
          projectId: selectedProject.id,
          userId: user.id,
        }),
      );
      dispatch(setShowCommitModal(true));
    }
    //    }
  };

  useKeyPress(['Enter'], commitToDevice, ['ctrlKey']);
  // TODO: refactor this
  const options: (Option | Space)[] = [
    {
      label: t('toolbar.resetDevice'),
      icon: <MdReplay color={'white'} />,
      action: () => {
        if (connectedDevice) {
          MqttService.publish(
            `device/${connectedDevice.id}/command`,
            JSON.stringify({ action: 'reset_device' }),
          );
        }
      },
      isDisabled: !connectedDevice,
      hide:
        !isEmbedded ||
        !user.additionalUserInfo?.showExperimentalFeatures ||
        !testInQuery,
      colorScheme: 'red',
    },
    { icon: 'space', label: 'Space 0' },

    {
      label: t('toolbar.shareProject'),
      icon: <FaShare color={'white'} />,
      action: () => {
        dispatch(setShowProjectShare(true));
      },
      hide: !isGroupProject || isPlatformio,
    },
    {
      label: t('toolbar.editInfo'),
      icon: <InfoIcon color={'white'} />,
      action: async () => {
        if (projectState.isNewProjectInfo) {
          const ok = await confirmExit();
          if (!ok) {
            return;
          }
        }
        if (selectedProject) {
          dispatch(setShowProjectInfo(!projectState.showProjectInfo));
        }
      },
      highlight: projectState.showProjectInfo,
      hide:
        !(selectedProject && selectedProject.isGroupProject) ||
        isPlatformio ||
        isSmall,
    },
    {
      label: t('toolbar.saveProject'),
      icon: <FaSave color={'white'} />,
      action: () => {
        if (selectedProject) {
          dispatch(saveProjectFiles(selectedProject.id));
        }
      },
      isDisabled: false,//!projectHasChanges,
      hide: isSmall,
      alert: fileHasError,
    },
    {
      label: t('toolbar.runFile'),
      icon: <FaPlay color={'white'} />,
      action: () => {
        if (displayedFile) {
          jupyterService.runCode();
          dispatch(saveProjectFiles(selectedProject.id));
        }
      },
      isDisabled: !displayedFile,
      hide: !isJupyterContentType,
    },
    {
      label: t('toolbar.runAll'),
      icon: <FaFastForward color={'white'} />,
      action: () => {
        if (displayedFile) {
          dispatch(saveProjectFiles(selectedProject.id));
          jupyterService.runAllCode();
        }
      },
      isDisabled: !displayedFile,
      hide: !isJupyterContentType,
    },
    {
      label: t('toolbar.newBlock'),
      icon: <PlusSquareIcon />,
      action: () => {
        if (displayedFile) {
          jupyterService.addNewBlock();
        }
      },
      isDisabled: !displayedFile,
      hide: !isJupyterContentType,
    },
    {
      label: t('toolbar.removeBlock'),
      icon: <DeleteIcon color={'white'} />,
      action: () => {
        if (displayedFile) {
          jupyterService.removeBlock();
        }
      },
      isDisabled: !displayedFile,
      hide: !isJupyterContentType,
    },
    {
      label: isActionsFile
        ? t('toolbar.runFile.actionsPy')
        : `${t('toolbar.runFile')} ${
            pythonLoading ? t('toolbar.runFile.pythonLoading') : ''
          }`,
      icon: <FaPlay color={'white'} />,
      action: () => {
        if (displayedFile) {
          dispatch(
            runFile({
              projectId: selectedProject.id,
              fileId: displayedFile.id,
            }),
          );
        }
      },
      isDisabled: isPythonRunDisabled || pythonTesting || isActionsFile,
      hide: isJupyterContentType || isPlatformio || isEmbedded || isProjectWeb,
      alert: fileHasError,
    },
    {
      label: t('toolbar.runAgain'),
      icon: <RepeatIcon color={'white'} />,
      action: () => {
        dispatch(increaseFileCounter());
      },
      hide: !isProjectWeb,
    },
    {
      label: `Test python${
        pythonLoading
          ? ' ' + t('toolbar.runFile.pythonLoading')
          : isPythonNotTestable
          ? ': ' + t('toolbar.testPython.notTestable')
          : ''
      }`,
      icon: <BiTestTube color={'white'} />,
      action: () => {
        if (displayedFile) {
          dispatch(
            testFile({
              fileId: displayedFile.id,
              templateId: selectedProject.templateId,
            }),
          );
        }
      },
      isDisabled: isPythonRunDisabled || isPythonNotTestable,
      hide: !isPython,
      alert: fileHasError,
    },
    {
      label: t('hover.ariaLabel.compileAndUpload'),
      icon: <ImArrowRight color={'white'} />,
      action: () => {
        if (selectedProject) {
          dispatch(setLastCompileId(null));
          dispatch(
            compileProject({
              project: selectedProject,
              ota: true,
              uploadType: UploadType.Mobile,
              hardReset: false,
            }),
          );
        }
      },
      hide: !isPlatformio,
      alert: fileHasError,
    },
    {
      label: connectedDevice
        ? `${t('device.connectedTo')} ${connectedDevice.name}`
        : t('device.connect'),
      icon: <ImLink color={'white'} />,
      action: () => {
        dispatch(setShowDeviceModal(true));
        if (user.additionalUserInfo && user.additionalUserInfo.schoolIds) {
          dispatch(getDevices(user.additionalUserInfo.schoolIds));
        }
        dispatch(getOnlineDevicesInfo());
      },
      hide: !isEmbedded,
      highlight: !!connectedDevice,
    },
    commit.showDiff || isRightSidebarOpen
      ? {
          label: t('toolbar.closeCommitList'),
          icon: <CloseIcon color={'white'} />,
          action: () => {
            dispatch(setIsRightSidebarOpen(false));
            dispatch(setShowCommitDiff(false));
            dispatch(setDiffWithWorkingDraft(false));
          },
          //hide: !isEmbedded,
        }
      : {
          label: t('toolbar.listCommits'),
          icon: <IoMdGitCommit color={'white'} />,
          action: () => {
            if (connectedDevice) {
              dispatch(
                getCommits({
                  projectId: selectedProject.id,
                  userId: user.id,
                  deviceId: connectedDevice?.id,
                }),
              );
              dispatch(setIsRightSidebarOpen(true));
              dispatch(setShowCommitDiff(true));
            } else {
              dispatch(
                getCommits({
                  projectId: selectedProject.id,
                  userId: user.id,
                }),
              );
              dispatch(setIsRightSidebarOpen(true));
            }
          },
          //     hide: !isEmbedded,
        },
    {
      label: hasError
        ? t('toolbar.commitCode.errors')
        : connectedDevice
        ? t('toolbar.commitToDevice')
        : t('toolbar.commitWithoutDevice'),
      icon: <RiGitRepositoryCommitsFill color={'white'} />,
      action: commitToDevice,
      isDisabled: hasError,
      //      hide: !isEmbedded,
    },
    {
      label: commit.commits.length
        ? connectedDevice
          ? t('toolbar.rerunCodeOnDevice')
          : t('device.notConnected')
        : t('toolbar.commitFirst'),
      icon: <MdReplay color={'white'} />,
      action: () => {
        if (connectedDevice) {
          if (lastRanProjectId === selectedProject.id) {
            MqttService.publish(
              `device/${connectedDevice.id}/command`,
              JSON.stringify({ action: 'run_again' }),
            );
            MqttService.publish(
              `device/${connectedDevice.id}/code`,
              'run_again',
            );
          } else {
            dispatch(pushCommitToDevice(commitIdToRunAgain));
          }
        }
      },
      isDisabled: !connectedDevice || !commit.commits.length,
      hide: !isEmbedded,
    },
    {
      label: t('toolbar.deviceLogs'),
      icon: <FaTerminal color={'white'} />,
      action: () => {
        dispatch(runLogs());
      },
      hide: !isEmbedded,
    },
    {
      label: t('toolbar.serialMonitor'),
      icon: <FiMonitor color={'white'} />,
      action: () => {
        dispatch(setupEspDevice());
      },
      hide: !isPlatformio || !isSerialAvailable(),
      highlight: !!espDevice,
    },
    {
      label: t('toolbar.deprecated.pcbType'),
      icon: <Text>{pcbType.toLocaleUpperCase()}</Text>,
      isDisabled: true,
      action: () => {
        dispatch(setPcbType(pcbType === PcbType.v1 ? PcbType.v2 : PcbType.v1));
      },
      hide: !isPlatformio,
    },
    {
      label: 'toolbar.trainProject',
      icon: <RepeatIcon color={'white'} />,
      action: () => {
        if (selectedProject) {
          dispatch(
            trainProject({
              project: selectedProject,
              shouldBootChat: selectedProject.type === ProjectType.RASA,
            }),
          );
        }
      },
      hide: selectedProject?.type !== ProjectType.RASA,
      alert: fileHasError,
    },
    {
      label: 'toolbar.chat',
      icon: <ChatIcon color={'white'} />,
      action: () => {
        if (isChatOptionAvailable) {
          dispatch(runChat(selectedProject));
        }
      },
      isDisabled: !isChatOptionAvailable,
      hide:
        selectedProject?.type !== ProjectType.RASA || isPlatformio || isSmall,
    },
    { icon: 'space', label: 'Space 2' },
    ...(fileState.showFileDiff === false
      ? [
          {
            label: t('toolbar.resetFile'),
            icon: <RepeatClockIcon color={'white'} />,
            action: () => {
              if (selectedProject && selectedProject.templateId) {
                dispatch(showFileDiff(selectedProject.id));
              }
            },
            isDisabled:
              !selectedProject?.templateId || projectState.showProjectDiff,
            hide: isSmall,
          },
        ]
      : [
          {
            label: t('toolbar.cancel'),
            icon: <CloseIcon color={'white'} />,
            action: () => {
              dispatch(setShowFileDiff(false));
            },
            isDisabled: false,
          },
          {
            label: t('toolbar.resetFile'),
            icon: <CheckIcon color={'white'} />,
            action: () => {
              dispatch(resetFile(displayedFile.id));
              resetJupyterEditor();
            },
            isDisabled: false,
          },
        ]),
    ...(projectState.showProjectDiff === false
      ? [
          {
            label: t('toolbar.resetProjectFiles'),
            icon: <FaTrashRestore color={'white'} />,
            action: () => {
              if (selectedProject && selectedProject.templateId) {
                dispatch(showProjectDiff(selectedProject.id));
              }
            },
            isDisabled: !selectedProject?.templateId || fileState.showFileDiff,
          },
        ]
      : [
          {
            label: t('toolbar.cancel'),
            icon: <CloseIcon color={'white'} />,
            action: () => {
              dispatch(setShowProjectDiff(false));
            },
            isDisabled: false,
          },
          {
            label: t('toolbar.resetProjectFiles'),
            icon: <CheckIcon color={'white'} />,
            action: () => {
              dispatch(resetProject(selectedProject.id));
              resetJupyterEditor();
            },
            isDisabled: false,
          },
        ]),
    {
      label: t('toolbar.resetFirmwareUSB'),
      icon: <FaPlug color={'white'} />,
      action: () => {
        if (selectedProject) {
          dispatch(
            compileProject({
              project: selectedProject,
              ota: true,
              uploadType: UploadType.USB,
              hardReset: true,
            }),
          );
        }
      },
      hide: !isPlatformio,
    },
    {
      label: `${t('toolbar.streamLive')} ${currentUsernameText}`,
      icon: <FaYoutube color={'white'} />,
      action: async () => {
        let username: string | undefined;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const newPublicUsername = await prompt({
            title: username
              ? `Username: ${username} is already in use`
              : t('prompt.changePublicUsername.title'),
          });
          if (newPublicUsername === null) {
            return;
          }

          // eslint-disable-next-line no-await-in-loop
          const ok = await AuthService.checkUsername(newPublicUsername);
          if (ok) {
            // eslint-disable-next-line consistent-return
            return dispatch(updateUser({ publicUsername: newPublicUsername }));
          }

          username = newPublicUsername;
        }
      },
      hide: !currentTemplateIsLive,
    },
  ];

  const resetJupyterEditor = () => {
    if (isJupyterContentType) {
      dispatch(setJupyterState(fileState.jupyterState + 1));
      setTimeout(() => {
        dispatch(setJupyterState(fileState.jupyterState + 2));
      }, 3000);
    }
  };

  return (
    <Center
      position="absolute"
      height="40px"
      right="0px"
      backgroundColor={isSmall ? '#181818' : '#181818'}
      zIndex={100}
    >
      <Flex flexWrap="wrap" justifyContent="space-evenly">
        {options
          .filter((o) => !o.hide)
          .map((option) => {
            if (option.hide) {
              return null;
            }

            if (option.icon === 'space') {
              return <Box key={option.label} mr="5" />;
            }

            return (
              <React.Fragment key={option.label}>
                <Tooltip shouldWrapChildren label={option.label} color="white">
                  <IconButton
                    bg={'#3055f6'}
                    color={
                      option.colorScheme
                        ? undefined
                        : option.highlight
                        ? 'white'
                        : 'primary'
                    }
                    aria-label={option.label}
                    icon={option.icon}
                    isDisabled={option.isDisabled}
                    colorScheme={option.colorScheme}
                    onClick={async () => {
                      if (option.alert) {
                        if (await confirmAlert()) {
                          option.action();
                        }
                      } else {
                        option.action();
                      }
                    }}
                    size="sm"
                  />
                </Tooltip>
                <Spacer width="4px" />
              </React.Fragment>
            );
          })}
      <LocaleSelect/>


      </Flex>

    </Center>
  );
};

export default ToolbarContainer;
